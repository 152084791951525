import { useCallback, useEffect, useRef, useState } from 'react';
import { AdvancedMarker, APIProvider, Map, MapMouseEvent, Pin, useMap } from '@vis.gl/react-google-maps';
import { MarkerClusterer, Marker } from '@googlemaps/markerclusterer';
import { Sheet } from 'react-modal-sheet';
import { Box, Button, IconButton, Stack, TextField, Typography } from '@mui/material';
import aituBridge from '@btsd/aitu-bridge';
import { Station } from './model/Station';
import { Connector } from './model/Connector';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import ShareIcon from '@mui/icons-material/Share';
import DirectionsIcon from '@mui/icons-material/Directions';
// import * as amplitude from '@amplitude/analytics-browser';
import { Polyline } from './Polyline';
import { Route } from './model/Route';
import EvConnectorsDropdown from './widgets/EvConnectorsDropdown';
import LoadingButton from '@mui/lab/LoadingButton';
import { EvStationOutlined, RouteOutlined } from '@mui/icons-material';

// amplitude.init('389bc32bd5bcf55c21dcc0b6b6d60be3'); // dev
// amplitude.init('7f269f305f5da7338443c8cea45a3118'); // prod

const colors = [
  '#FF5733', // Оранжевый
  '#33FF57', // Зелёный
  '#3357FF', // Синий
  '#F39C12', // Жёлто-оранжевый
  '#9B59B6', // Фиолетовый
  '#E74C3C', // Красный
  '#1ABC9C', // Бирюзовый
  '#2ECC71', // Светло-зелёный
  '#3498DB', // Голубой
  '#E67E22'  // Тёмно-оранжевый
];

type Point = { lat: number, lng: number }

const App = () => {
  // const [message, setMessage] = useState<string | undefined>(undefined);

  const googleMapApiKey = process.env.REACT_APP_GOOGLE_MAP_API_KEY!;

  const [selectedStation, setSelectedStation] = useState<Station | undefined>();

  const [routeLoading, setRouteLoading] = useState(false);
  const [route, setRoute] = useState<Route | undefined>();
  const [selectedConnectors, setSelectedConnectors] = useState<Connector[]>([]);

  const handleConnectorsChanged = (connectors: Connector[]) => {
    setSelectedConnectors(connectors);
  }

  // const fetchStations = useCallback(async () => {
  //   console.log('fetchStation started')
  //   const response = await fetch('https://us-central1-kz-ev-chargers-map.cloudfunctions.net/getAllStations', {
  //     method: 'POST',
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify({ data: { invalidateCache: false } })
  //   });
  //   if (!response.ok) {
  //     // TODO: show error and retry button
  //     return;
  //   }
  //   const data = await response.json();
  //   setStations(JSON.parse(data.result.stations))
  //   console.log('fetchStation finished')
  // }, [])

  // useEffect(() => {
  //   console.log('useEffect called')
  //   if (stations.length === 0) {
  //     fetchStations()
  //   }
  // }, [fetchStations, stations]);

  const handleMarkerClick = (station: Station) => {
    setSelectedStation(station);

    // const eventProperties = {
    //   provider_name: station.provider?.name,
    //   station_id: station.id,
    //   station_title: station.place.title,
    //   station_location: `${station.location.latitude}, ${station.location.longitude}`,
    // };
    // amplitude.track('HomeScreen_show_station_info', eventProperties);
  }

  const handleShareClick = async () => {
    if (!selectedStation) return;

    if (aituBridge.supports('share')) {
      const result = await aituBridge.share(`https://evpoint.kz/stations?id=${selectedStation?.id}`);

      const eventProperties = {
        provider_name: selectedStation.provider?.name,
        station_id: selectedStation.id,
      };
      // amplitude.track('ChargingStationInfoScreen_open_share_app_chooser', eventProperties);
    }
  }

  const handlePaymentClick = async () => {
    if (!selectedStation || !selectedStation.paymentLink) return;

    const canOpenExternalUrl = aituBridge.supports('openExternalUrl');
    if (canOpenExternalUrl) {
      aituBridge.openExternalUrl(selectedStation.paymentLink);

      // const eventProperties = {
      //   provider_name: selectedStation.provider?.name,
      //   station_id: selectedStation.id,
      // };
      // amplitude.track('ChargingStationInfoScreen_open_provider_app', eventProperties);
    }
  }

  const handleOpenSupportClick = async () => {
    if (!selectedStation || !selectedStation.provider?.supportLink) return;

    const canOpenExternalUrl = aituBridge.supports('openExternalUrl');
    if (canOpenExternalUrl) {
      aituBridge.openExternalUrl(selectedStation.provider.supportLink);

      // const eventProperties = {
      //   provider_name: selectedStation.provider?.name,
      //   station_id: selectedStation.id,
      // };
      // amplitude.track('ChargingStationInfoScreen_open_provider_support', eventProperties);
    }
  }

  const handleOpenNavigationClick = async () => {
    if (!selectedStation) return;

    const canOpenExternalUrl = aituBridge.supports('openExternalUrl');
    if (canOpenExternalUrl) {
      aituBridge.openExternalUrl(`https://maps.google.com/?daddr=${selectedStation.location.latitude},${selectedStation.location.longitude}`);

      // const eventProperties = {
      //   provider_name: selectedStation.provider?.name,
      //   station_id: selectedStation.id,
      // };
      // amplitude.track('ChargingStationInfoScreen_open_provider_support', eventProperties);
    }
  }

  const [startPoint, setStartPoint] = useState<Point | undefined>(undefined);
  const [endPoint, setEndPoint] = useState<Point | undefined>(undefined);
  const [carRange, setCarRange] = useState<number>(250);
  const [chargesCount, setChargesCount] = useState<number | undefined>();

  const fetchRoute = useCallback(async (pointA: Point, pointB: Point, selectedConnectors: Connector[], carRange: number) => {
    setRouteLoading(true);
    console.log('fetchRoute started', pointA, pointB, selectedConnectors)
    const routeUrl = `https://api.evpoint.kz/internal/route`
    // const routeUrl = 'http://127.0.0.1:5001/kz-ev-chargers-map/us-central1/internal/internal/route'
    try {
      const connectorTypes = selectedConnectors.map(c => c.name);
      const response = await fetch(`${routeUrl}?origin=${pointA.lat},${pointA.lng}&destination=${pointB.lat},${pointB.lng}&connectorTypes=${connectorTypes}&carRange=${carRange}`, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        console.log('unable to get route')
        return;
      }
      const data = await response.json();
      setRoute(data);

      console.log('fetchRoute finished')
    } catch (error) {

    } finally {
      setRouteLoading(false);
    }
  }, []);

  const handleOnMapClick = useCallback((event: MapMouseEvent) => {
    setSelectedStation(undefined);
    if (event.detail.latLng !== null) {
      if (!startPoint) {
        setStartPoint({ lat: event.detail.latLng?.lat, lng: event.detail.latLng?.lng });
        console.log('start point')
      } else {
        setEndPoint({ lat: event.detail.latLng?.lat, lng: event.detail.latLng?.lng });
        console.log('end point')
      }
    }
  }, [startPoint]);

  const updateChargersCount = useCallback(() => {
    if (!route) return;

    const rangeInMeters = carRange * 1000;
    console.log(calculateCharges(route.distance.value, rangeInMeters))
    setChargesCount(calculateCharges(route.distance.value, rangeInMeters));
  }, [route, carRange])

  useEffect(() => {
    updateChargersCount();
  }, [updateChargersCount]);

  const handleLoadRoute = useCallback(() => {
    if (startPoint && endPoint) {
      fetchRoute(startPoint, endPoint, selectedConnectors, carRange);
    }
  }, [carRange, endPoint, fetchRoute, selectedConnectors, startPoint]);

  const handleReset = () => {
    setRoute(undefined);

    setStartPoint(undefined);
    setEndPoint(undefined);

    setSelectedConnectors([])
    setChargesCount(undefined);
  }

  const handleCarRangeChange = (range: number) => {
    if (range <= 0) return;

    setCarRange(range);
  }

  return (
    <>
      <Box>
        <Box
          style={{
            position: 'absolute',
            top: '10px',
            left: '10px',
            zIndex: 10,
            backgroundColor: 'white',
            padding: '10px',
            borderRadius: '8px',
            boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
            display: 'flex',
            flexDirection: 'column',
            gap: '10px', // Расстояние между текстовыми полями
          }}
        >
          <TextField
            value={
              startPoint
                ? `${startPoint.lat}, ${startPoint.lng}`
                : 'Точка А не выбрана'
            }
            label={startPoint ? 'Точка А' : null}
            slotProps={{
              htmlInput: {
                readOnly: true
              }
            }}
          />
          <TextField
            value={
              endPoint
                ? `${endPoint.lat}, ${endPoint.lng}`
                : 'Точка Б не выбрана'
            }
            label={endPoint ? 'Точка Б' : null}
            slotProps={{
              htmlInput: {
                readOnly: true
              }
            }}
          />
          <Box mt={1}>
            <Typography>Информация об авто:</Typography>
            <EvConnectorsDropdown
              initialSelectedConnectors={selectedConnectors.map(c => c.name)}
              onConnectorsChange={handleConnectorsChanged} />
            <TextField
              value={carRange}
              onChange={e => { handleCarRangeChange(Number(e.target.value)) }}
              label={'Дальность хода в км.'}
              type='number'
              margin='normal'
            />
          </Box>
          {
            route && <Box display={'flex'} gap={1}>
              <RouteOutlined />
              <Typography>
                {route.distance.text} - {route.duration.text}
              </Typography>
            </Box>
          }
          {
            chargesCount !== undefined &&
            <Box display={'flex'} gap={1}>
              <EvStationOutlined />
              <Typography>
                Требуется подзарядок: {chargesCount}
              </Typography>
            </Box>
          }
          <LoadingButton onClick={handleLoadRoute} loading={routeLoading} disabled={!startPoint || !endPoint}>
            Построить маршрут
          </LoadingButton>
          <Button onClick={handleReset} disabled={startPoint === undefined}>
            Очистить
          </Button>
        </Box>
        <APIProvider apiKey={googleMapApiKey}>
          <Map
            style={{ width: '100vw', height: '100vh' }}
            defaultCenter={{ lat: 51.169392, lng: 71.449074 }}
            defaultZoom={12}
            minZoom={3}
            gestureHandling={'greedy'}
            fullscreenControl={false}
            onClick={(event: MapMouseEvent) => {
              handleOnMapClick(event);
            }}
            disableDefaultUI={false}
            mapTypeControl={false}
            streetViewControl={false}
            mapId={'3dfe11e1ec4e9a3b'}
          >

            <>
              {route?.polyline_segments.map((p, idx) => {
                return <Polyline
                  path={p}
                  strokeColor={colors[idx]}
                />
              })}
            </>

            {
              startPoint &&
              <AdvancedMarker
                position={{ lat: startPoint.lat, lng: startPoint.lng }}
                clickable={false}
                zIndex={500}
              >
                <Pin background={'#228B22'} glyphColor={'#006400'} borderColor={'#228B22'} />
              </AdvancedMarker>
            }

            {
              endPoint &&
              <AdvancedMarker
                position={{ lat: endPoint.lat, lng: endPoint.lng }}
                clickable={false}
                zIndex={500}
              >
              </AdvancedMarker>
            }

            <StationMarkers stations={route?.stations || []} onMarkerClick={handleMarkerClick} />
          </Map>
        </APIProvider>
      </Box>

      <Sheet detent='content-height' isOpen={selectedStation !== undefined} onClose={() => setSelectedStation(undefined)}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <Box marginX={2} marginBottom={2}>

              <Typography variant='h6'>{selectedStation?.place.title}</Typography>
              {selectedStation?.provider && <Typography variant='h6'> | {selectedStation?.provider?.name}</Typography>}
              <Stack direction="row">
                <Typography variant='caption'>{selectedStation?.place.address}</Typography>
                {selectedStation?.place.city && <Typography variant='caption'>, {selectedStation?.place.city}</Typography>}
              </Stack>
              {selectedStation?.wattage && <Typography variant='subtitle1'>Мощность: {selectedStation?.wattage} кВт</Typography>}

              {/* <Stack overflow={'auto'} direction="row" spacing={2} marginTop={1}>
                <Button variant="contained" disableElevation onClick={handlePaymentClick}>Оплатить</Button>
                <IconButton size="medium" onClick={handleOpenNavigationClick}><DirectionsIcon /></IconButton>
                <IconButton size="medium" onClick={handleShareClick}><ShareIcon /></IconButton>
                {selectedStation?.provider?.supportLink && <IconButton size="medium" onClick={handleOpenSupportClick}><HeadsetMicIcon /></IconButton>}
              </Stack> */}

            </Box>
          </Sheet.Content>
        </Sheet.Container>
      </Sheet>

      {/* <Sheet detent='content-height' isOpen={message !== undefined} onClose={() => { }}>
        <Sheet.Container>
          <Sheet.Header />
          <Sheet.Content>
            <Typography>{message}</Typography>
          </Sheet.Content>
        </Sheet.Container>
      </Sheet> */}
    </>
  )
};

const StationMarkers = (props: { stations: Station[], onMarkerClick: (station: Station) => void }) => {
  const map = useMap();
  const [markers, setMarkers] = useState<{ [id: string]: Marker }>({});
  const clusterer = useRef<MarkerClusterer | null>(null);

  const handleClick = useCallback((ev: google.maps.MapMouseEvent, station: Station) => {
    if (!map) return;
    if (!ev.latLng) return;
    console.log('marker clicked:', ev.latLng.toString());
    map.panTo(ev.latLng);
    props.onMarkerClick(station);
  }, [map, props]);

  // Initialize MarkerClusterer, if the map has changed
  useEffect(() => {
    if (!map) return;
    if (!clusterer.current) {
      clusterer.current = new MarkerClusterer({ map });
    }
  }, [map]);

  // Update markers, if the markers array has changed
  useEffect(() => {
    clusterer.current?.clearMarkers();
    clusterer.current?.addMarkers(Object.values(markers));
  }, [markers]);

  const setMarkerRef = (marker: Marker | null, key: string) => {
    if (marker && markers[key]) return;
    if (!marker && !markers[key]) return;

    // setMarkers(prev => {
    //   if (marker) {
    //     return { ...prev, [key]: marker };
    //   } else {
    //     const newMarkers = { ...prev };
    //     delete newMarkers[key];
    //     return newMarkers;
    //   }
    // });
  };

  return (
    <>
      {props.stations.map((station: Station) => (
        <AdvancedMarker
          key={station.id}
          position={{ lat: station.location.latitude, lng: station.location.longitude }}
          ref={marker => setMarkerRef(marker, station.id)}
          clickable={true}
          onClick={event => handleClick(event, station)}
        >
          <Pin background={'#EA6035'} glyphColor={'#F1F614'} borderColor={'#EA6035'} />
        </AdvancedMarker>
      ))}
    </>
  );
};

function calculateCharges(distance: number, range: number) {
  if (range <= 0) {
    throw new Error("Запас хода должен быть больше 0");
  }
  // Рассчитываем количество зарядок
  return Math.ceil(distance / range) - 1;
}

export default App;
